<template>
  <b-container>
    <div class="text-box">
      <h1>{{ $t("results.title") }}</h1>
      <div class="question-box">
          <div class="result">
            <div>
              <h2>{{ $t("results.subtitle4") }}</h2>
              <p class="result-description">{{ $t("results.text4") }}</p>
            </div>
              <div class="result-graph">
                <div class="d-flex justify-content-around">
                  <p>{{$t("results.graph1")}}</p>
                  <p>{{$t("results.graph2")}}</p>
                </div>
                  <bar-chart :chartData="results.total" :scale="scale" :width="300" :height="80"></bar-chart>
              </div>
          </div>
          <div v-if="showCar" class="mt-4">
            <h5>{{ $t("questions.q3a") }}</h5>
            <b-form-group v-slot="{ ariaDescribedby }">
              <div class="multiple-choice">
                <b-form-radio 
                  v-model="replaceCar"
                  :aria-describedby="ariaDescribedby"
                  class="radio-answer"
                  :value="true"
                  required
                >
                  {{ $t("questions.q3aa1") }}
                </b-form-radio>
                <b-form-radio 
                  v-model="replaceCar"
                  :aria-describedby="ariaDescribedby"
                  class="radio-answer"
                  :value="false"
                  required
                >
                  {{ $t("questions.q3aa2") }}
                </b-form-radio>
              </div>
            </b-form-group>
          </div>
          <div v-if="showHeating">
            <h5>{{ $t("questions.q10") }}</h5>
            <b-form-group v-slot="{ ariaDescribedby }">
              <div class="multiple-choice">
                <b-form-radio 
                  v-model="replaceHeating" 
                  :aria-describedby="ariaDescribedby"
                  class="radio-answer"
                  :value="true"
                  required
                >
                  {{ $t("questions.q3aa1") }}
                </b-form-radio>
                <b-form-radio 
                  v-model="replaceHeating"
                  :aria-describedby="ariaDescribedby"
                  class="radio-answer"
                  :value="false"
                  required
                >
                  {{ $t("questions.q3aa2") }}
                </b-form-radio>
              </div>
            </b-form-group>
          </div>
          <b-button class="button-secondary mt-4" v-if="detail == false" @click="details">{{ $t("questions.detailon") }}</b-button>
          <b-button class="button-secondary mt-4" v-if="detail" @click="nodetails">{{ $t("questions.detailoff") }}</b-button>
        <hr class="mt-4">
        <div v-if="detail">
          <div class="result">
            <div>
              <h2>{{ $t("results.subtitle1") }}</h2>
              <p class="result-description">
                {{ $t("results.text1") }}
              </p>
            </div>
            <div class="result-graph">
              <div class="d-flex justify-content-around">
                <h5>{{$t("results.graph1")}}</h5>
                <h5>{{$t("results.graph2")}}</h5>
              </div>
              <bar-chart :chartData="results.car" :scale="scale" :width="300" :height="80"></bar-chart>
            </div>
          </div>
          <hr class="mt-4">
            <div class="result">
              <div>
                <h2>{{ $t("results.subtitle2") }}</h2>
                <p class="result-description">{{ $t("results.text2") }}</p>
              </div>
              <div class="result-graph">
                <div class="d-flex justify-content-around">
                  <h5>{{$t("results.graph1")}}</h5>
                  <h5>{{$t("results.graph2")}}</h5>
                </div>
                <bar-chart :chartData="results.flight"  :scale="scale"  :width="300" :height="80"></bar-chart>
              </div>
            </div>
          <hr class="mt-4">
            <div class="result">
              <div>
                <h2>{{ $t("results.subtitle3") }}</h2>
                <p class="result-description">{{ $t("results.text3") }}</p>
              </div>
              <div class="result-graph">
              <div class="d-flex justify-content-around">
                <h5>{{$t("results.graph1")}}</h5>
                <h5>{{$t("results.graph2")}}</h5>
              </div>
                <bar-chart :chartData="results.heating"  :scale="scale"  :width="300" :height="80"></bar-chart>
              </div>
            </div>
          <hr class="mt-4">
        </div>
        <h2>{{ $t("results.subtitle5") }}</h2>
        <p v-if="displayedTipps.length != 0">{{ $t("results.text5") }}</p>
        <p v-if="displayedTipps.length == 0"> {{ $t("results.notipp") }} </p>
        <div class="row">
          <div class="col-12 col-md-6 tipps" v-for="(tipp,i) in displayedTipps" :key="i">
            <img class="tipp-icon" :src="tipp.tipp.imgUrl" alt="velo">
            <div>
              <h5>{{ tipp.tipp.title }}</h5>
              <p v-if="answers.calculationType == 'Mich'"> {{ tipp.tipp.desc }}</p>
              <p v-if="answers.calculationType == 'Haushalt'"> {{ tipp.tipp.descHH }}</p>
              <h4>
                +{{ tipp.rating }} CHF
              </h4>
            </div>
          </div>
        </div>
        <hr class="mt-4">
        <div class="button-social text-center">
          <b-button :to="{ name: 'Questions', query: {
          calculationType: answers.calculationType,
          kilometer: answers.kilometer,
          carType: answers.carType,
          carSize: answers.carSize,
          shortFlights: answers.shortFlights,
          midFlights: answers.midFlights,
          longFlights: answers.longFlights,
          business: answers.business,
          heatingType: answers.heatingType,
          houseType: answers.houseType,
          livingArea: answers.livingArea,
          numberPeople: answers.numberPeople
          }}" class="button-secondary">
            {{ $t("results.button") }}
          </b-button>
          <hr class="mt-4 social-break">
          <div class="social-sharing align-items-center text-center">
            <h3>{{ $t("results.share") }}</h3>
            <ShareNetwork
                network="facebook"
                url="https://co2-rechner.jglp.ch"
                :title= "$t('socialshare.title')"
                :description="$t('socialshare.description')"
                :hashtags="$t('socialshare.hashtagsFB')"
              >
                <img class="social-share" src="../../src/assets/facebook.svg" alt="velo">
            </ShareNetwork>
            <ShareNetwork
                network="twitter"
                :title= "$t('socialshare.description')"
                :hashtags= "$t('socialshare.hashtags')"
                twitter-user="jglpCH"
                url="https://co2-rechner.jglp.ch"
              >
                <img class="social-share" src="../../src/assets/twitter.svg" alt="velo">
            </ShareNetwork>
            <ShareNetwork
                network="whatsapp"
                url="https://co2-rechner.jglp.ch"
                :title= "$t('socialshare.title')"
                :description="$t('socialshare.description')"
              >
                <img class="social-share" src="../../src/assets/whatsapp.svg" alt="velo">
            </ShareNetwork>
            <ShareNetwork
                network="linkedin"
                url="https://co2-rechner.jglp.ch"
              >
                <img class="social-share" src="../../src/assets/linkedin.svg" alt="velo">
            </ShareNetwork>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import BarChart from "@/components/BarChart";
import {tipps} from "@/tipps.js";

function getCharDataForData(data){  
       return  {
        labels: ["2022", "2030"],
        datasets: [{
          datalabels: {
            color: "black",
            anchor: "end",
            align: "right",
            get font(){
              return {
              weight: 'bold',
              size: '18',
              family: 'Ubuntu'}
            },
            set font(font){
              // FIXME
            },
            formatter: function(value) {
              return value + ' CHF';
            }
          },
          label: "Betrag",
          borderWidth: 1,
          backgroundColor: getColor(data),
          borderColor: [
            "grey",
            "grey"
          ],
          pointBorderColor: "#2554FF",
          data: data,
        }]
       }
       function getColor(data) {
          if (data[0] > 0 && data[1] >= 0) {
            return [
              "rgba(168, 206, 63, 0.4)",
              "rgba(168, 206, 63, 0.4)"
              ]
          } else if (data[0] > 0 && data[1] <= 0) {
              return [
                "rgba(168, 206, 63, 0.4)",
                "rgba(250,158,158,1)"
              ]
          } else if (data[0] <= 0 && data[1] >= 0) {
              return [
                "rgba(250,158,158,1)",
                "rgba(168, 206, 63, 0.4)"
              ]
          } else {
            return [
              "rgba(250,158,158,1)",
              "rgba(250,158,158,1)"
            ]
          }
        }
      }

export default {
  name: "Questions",
  components: {
    BarChart
  },
  created() {
    this.answers = this.$route.query;
  }, 
  computed: {
    results() {
      const results = {
        car: {},
        flight: {},
        heating: {},
        total: {},
        y2022: {
          car: 0,
          flight: 0,
          heating: 0,
          total: 0
        },
        y2030: {
          car: 0,
          flight: 0,
          heating: 0,
          total: 0
        }
      }
      
      if (this.answers == undefined) {
        return results
      }  
    
      const answers = this.answers

    if (answers.calculationType == "Mich") {
      answers.numberPeople = 1;
    }

    if (answers.carType == "Elektroauto" || answers.carType =="Kein Auto") {
      results.y2022.car = Math.round(0);
      results.y2030.car = Math.round(0);
    } else if (answers.carType == "Benzin" && answers.carSize =="Kleinklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 6.83 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 6.83 * 0.07);
    } else if (answers.carType == "Benzin" && answers.carSize =="Mittelklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 8.42 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 8.42 * 0.07);
    } else if (answers.carType == "Benzin" && answers.carSize =="SUV") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 10.01 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 10.01 * 0.07);
    } else if (answers.carType == "Diesel" && answers.carSize =="Kleinklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 5.23 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 5.23 * 0.07);
    } else if (answers.carType == "Diesel" && answers.carSize =="Mittelklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 6.70 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 6.70 * 0.07);
    } else if (answers.carType == "Diesel" && answers.carSize =="SUV") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 8.39 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 8.39 * 0.07);
    } else if (answers.carType == "Erdgas" && answers.carSize =="Kleinklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 4.94 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 4.94 * 0.07);
    } else if (answers.carType == "Erdgas" && answers.carSize =="Mittelklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 6.08 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 6.08 * 0.07);
    } else if (answers.carType == "Erdgas" && answers.carSize =="SUV") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 7.22 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 7.22 * 0.07);
    } else if (answers.carType == "Hybrid" && answers.carSize =="Kleinklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 4.22 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 4.22 * 0.07);
    } else if (answers.carType == "Hybrid" && answers.carSize =="Mittelklasse") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 5.2 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 5.2 * 0.07);
    } else if (answers.carType == "Hybrid" && answers.carSize =="SUV") {
      results.y2022.car = Math.round(-answers.kilometer / 100 * 6.18 * 0.055);
      results.y2030.car = Math.round(-answers.kilometer / 100 * 6.18 * 0.07);
    }

    results.y2022.flight = 80 * answers.numberPeople - answers.shortFlights * 30 - answers.midFlights * 60 - answers.longFlights * 90 - answers.business * 30
    results.y2030.flight = 77 * answers.numberPeople - answers.shortFlights * 30 - answers.midFlights * 60 - answers.longFlights * 90 - answers.business * 30
    

    if (answers.heatingType == "Ölheizung") {
      results.y2022.heating = Math.round(15 * answers.numberPeople - ((answers.livingArea * answers.houseType * 0.000265320) * 24));
      results.y2030.heating = Math.round(39 * answers.numberPeople - ((answers.livingArea * answers.houseType * 0.000265320) * 114 ));
    } else if (answers.heatingType == "Gasheizung") {
      results.y2022.heating = Math.round(15 * answers.numberPeople - ((answers.livingArea * answers.houseType * 0.00020267983785613) * 24));
      results.y2030.heating = Math.round(39 * answers.numberPeople - ((answers.livingArea * answers.houseType * 0.00020267983785613) * 114));
    } else if (answers.heatingType == "Wärmepumpe" || answers.heatingType == "Andere") {
      results.y2022.heating = 15 * answers.numberPeople;
      results.y2030.heating = 39 * answers.numberPeople;
    }

    if(this.replaceCar){
      results.y2030.car = 0;
    }

    if(this.replaceHeating) {
      results.y2030.heating = 39 * answers.numberPeople;
    }

    results.y2022.total = results.y2022.car + results.y2022.flight + results.y2022.heating;
    results.y2030.total = results.y2030.car + results.y2030.flight + results.y2030.heating;

    results.car =  getCharDataForData([results.y2022.car, results.y2030.car]);
    results.flight =  getCharDataForData([results.y2022.flight, results.y2030.flight]);
    results.heating =  getCharDataForData([results.y2022.heating, results.y2030.heating]);
    results.total =  getCharDataForData([results.y2022.total, results.y2030.total]);

    return results
    },
    showCar() {
      if (this.answers.carType == "Hybrid" || this.answers.carType == "Benzin" || this.answers.carType == "Diesel" || this.answers.carType == "Erdgas") {
        return true;
      } 
      return false;
    },
    showHeating() {
      if (this.answers.heatingType == "Ölheizung" || this.answers.heatingType == "Gasheizung") {
        return true;
      } 
      return false;
    },
    displayedTipps(){
        const ratedTipps = [];
        tipps(this.$i18n).forEach(tipp => {
          ratedTipps.push({
            tipp,
            rating: tipp.rate(this.$route.query, this.results)
          });
        });
        return ratedTipps.filter(e => e.rating > 0 ).sort((a, b) => b.rating - a.rating).slice(0,4);
    },
    scale(){
      const allValues = [this.results.y2022.car,
        this.results.y2030.car,
        this.results.y2022.flight,
        this.results.y2030.flight,
        this.results.y2022.heating,
        this.results.y2030.heating,
        this.results.y2022.total,
        this.results.y2030.total,
        ]

      const smallestValue = Math.min(...allValues);
      const highestValue = Math.max(...allValues);

      const highestAbsoluteValue = Math.max(highestValue, Math.pow(Math.pow(smallestValue,2),0.5), 150 * this.answers.numberPeople);

      return {min: highestAbsoluteValue * -1, max: highestAbsoluteValue};
    }
  },
  data() {
    return {
      detail: false,
      answers: {},
      replaceCar: false,
      replaceHeating: false
    }
  },
  methods: {
    details() {
      this.detail = true
    },
    nodetails() {
      this.detail = false
    }
  }
};
</script>

<style scoped>

.question-box {
  text-align: left;
  box-shadow: 0 0 0 0.5px rgb(0 20 40 / 10%), 0 2px 8px 0 rgb(50 55 90 / 20%);
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 40px;
}

.text-box {
  text-align: left;
}

.tipp-icon {
  margin-right: 30px;
}

.social-share {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

@media (min-width: 992px) {

  .social-sharing {
    display: flex;
    text-align: left;
  }

  .button-social {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 0px;
  }

  .social-break {
    display: none;
  }


  .result-description {
    margin-right: 20px;
    flex-shrink: 3;
  }

  .result-graph {
    flex-grow: 2;
    min-width: 40%;
    max-width: 80%;
    text-align: center;
  }

  .tipps {
    display: flex;
    align-items: center;
  }

}
</style>
