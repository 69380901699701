<script>
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  props: {
      chartData: Object,
      scale: {
        type: Object,
        default: function(){
          return {
            max: 400,
            min: -400
          }
        },
      },
  },
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options, this.scale)
  },
  computed: {
    options() {
      return {
        scales: {
          yAxes: [{
            ticks:{
              fontSize: 14,
              fontColor: "black",
              fontFamily: "'Ubuntu','sans-serif'"
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMin: this.scale.min,
              suggestedMax: this.scale.max,
              fontSize: 14,
              fontColor: "black",
              fontFamily: "'Ubuntu','sans-serif'"
            },
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: true
      }
    }}
}
</script>