<template>
  <b-container>
    <div class="text-box">
      <h1>{{ $t("annahmen.title") }}</h1>
      <div class="question-box">
      <h2>{{ $t("annahmen.subtitle0") }}</h2>
      <p>{{$t("annahmen.text0a")}}</p>
      <p>{{$t("annahmen.text0b")}}</p>
      <h2>{{ $t("annahmen.subtitle1") }}</h2>
      <h5>{{ $t("annahmen.subtitle1a") }}</h5>
      <p>{{$t("annahmen.text1aa")}}</p>
      <p>{{ $t("annahmen.text1a1") }}</p>
      <p>{{ $t("annahmen.text1a2") }}</p>
      <h5>{{ $t("annahmen.subtitle1b") }}</h5>
      <p>{{ $t("annahmen.text1b") }}</p>
      <div style="overflow-x:auto;">
      <table class="table">
        <tr>
          <th> </th>
          <th>{{ $t("annahmen.text1b1") }}</th>
          <th>{{ $t("annahmen.text1b2") }}</th>
          <th>{{ $t("annahmen.text1b3") }}</th>
          <th>{{ $t("annahmen.text1b4") }}</th>
        </tr>
        <tr>
          <td>{{ $t("annahmen.text1b5") }}</td>
          <td>6.8</td>
          <td>5.2</td>
          <td>4.2</td>
          <td>4.9</td>
        </tr>
        <tr>
          <td>{{ $t("annahmen.text1b6") }}</td>
          <td>8.4</td>
          <td>6.7</td>
          <td>5.2</td>
          <td>6.2</td>
        </tr>
        <tr>
          <td>{{ $t("annahmen.text1b7") }}</td>
          <td>10.0</td>
          <td>8.4</td>
          <td>6.2</td>
          <td>7.2</td>
        </tr>
      </table>
      </div>
      <p> {{ $t("annahmen.text1b8") }} 
      <a target="_blank" href="https://co2.myclimate.org/en/car_calculators/new">https://co2.myclimate.org/en/car_calculators/new</a></p>
      <hr>
      <h2>{{ $t("annahmen.subtitle2") }}</h2>
      <h5>{{ $t("annahmen.subtitle2a") }}</h5>
      <p>{{ $t("annahmen.text2a1") }}</p>
      <p>{{ $t("annahmen.text21a2") }}</p>
      <p>{{ $t("annahmen.text2a2") }}</p>
      <p>{{ $t("annahmen.text2a3") }}</p>
      <h5>{{ $t("annahmen.subtitle2b") }}</h5>
      <p>{{ $t("annahmen.text2b1") }}</p>
      <p>{{ $t("annahmen.text2b2") }}</p>
      <p>{{ $t("annahmen.text2b3") }}</p>
      <h5>{{ $t("annahmen.subtitle2c") }}</h5>
      <p>{{ $t("annahmen.text2c1") }}</p>
      <p>{{ $t("annahmen.text21c1") }}</p>
      <p>{{ $t("annahmen.text2c2") }}</p>
      <p>{{ $t("annahmen.text2c3") }}</p>
      <p>{{ $t("annahmen.text2c4") }}</p>
      <p>{{ $t("annahmen.text2c5") }}</p>
      <hr>
      <h2>{{ $t("annahmen.subtitle3") }}</h2>
      <h5>{{ $t("annahmen.subtitle3a") }}</h5>
        <p>{{ $t("annahmen.text3a") }}</p>
      <p>{{ $t("annahmen.text3a1") }}</p>
      <p>{{ $t("annahmen.text3a2") }}</p>
      <p>{{ $t("annahmen.text3a3") }}</p>
      <ul>
        <li><a href="http://doi.org/10.5334/bc.61">Pathways toward a carbon-neutral Swiss residential building stock. Buildings and Cities, 1(1), 579–593 </a></li>
        <li><a href="https://www.bfe.admin.ch/bfe/de/home/versorgung/statistik-und-geodaten/energiestatistiken/energieverbrauch-nach-verwendungszweck.html">BFE Energieverbrauch der privaten Haushalte</a></li>
        <li><a href="https://www.bafu.admin.ch/bafu/en/home/topics/climate/state/data/greenhouse-gas-inventory.html">BAFU Emissionsfaktoren</a></li>
      </ul>

      <h5>{{ $t("annahmen.subtitle3b") }}</h5>
      <p>{{ $t("annahmen.text3b1") }}</p>
      <p>{{ $t("annahmen.text3b2") }}</p>
      <h5>{{ $t("annahmen.subtitle3c") }}</h5>
      <p>{{ $t("annahmen.text3c1") }}</p>
      <hr>
      <b-button :to="{name: 'Questions'}" class="button-secondary">{{ $t("annahmen.button") }}</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>


export default {
  name: "Annahmen",
  components: {
  }
}
</script>

<style scoped>
.text-box {
  text-align: left;
}

.question-box {
  text-align: left;
  box-shadow: 0 0 0 0.5px rgb(0 20 40 / 10%), 0 2px 8px 0 rgb(50 55 90 / 20%);
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 40px;
}
</style>