<template>
  <b-container>
    <div class="text-box">
      <h1>{{ $t("questions.title") }}</h1>
      <div class="question-box">
        <h2>{{ $t("questions.subtitle0") }}</h2>
        <h5>{{ $t("questions.q0") }}</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="multiple-choice">
              <b-form-radio 
                v-model="questions.calculationType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Mich"
                required
              >
                {{ $t("questions.q0a1") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.calculationType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Haushalt"
                required
              >
                {{ $t("questions.q0a2") }}
              </b-form-radio>
            </div>
          </b-form-group>
          <div v-if="questions.calculationType == 'Haushalt'">
            <h5>{{ $t("questions.q9") }}</h5>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="questions.numberPeople"
                id="anzPersonen"
                class="number-input"
                type="number"
                placeholder="1"
                required
              ></b-form-input>
              <p class="p-2 mb-0">{{ $t("questions.q9a1") }}</p>
            </div>
          </div>
        <hr class="mt-4">
        <h2>{{ $t("questions.subtitle1") }}</h2>
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q1HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q1") }}</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="multiple-choice">
              <b-form-radio 
                v-model="questions.carType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Benzin"
                required
              >
                {{ $t("questions.q1a2") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Diesel"
                required
              >
                {{ $t("questions.q1a3") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Erdgas"
                required
              >
                {{ $t("questions.q1a4") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Hybrid"
                required
              >
                {{ $t("questions.q1a5") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carType" 
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Elektroauto"
                required
              >
                {{ $t("questions.q1a1") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Kein Auto"
                required
              >
                {{ $t("questions.q1a6") }}
              </b-form-radio>
            </div>
          </b-form-group>
          <div v-if="questions.carType == 'Benzin' || questions.carType == 'Diesel' || questions.carType == 'Hybrid' || questions.carType == 'Erdgas'">
          <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q2HH") }}</h5>
          <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q2") }}</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="multiple-choice">
              <b-form-radio 
                v-model="questions.carSize" 
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Kleinklasse"
                required
              >
                {{ $t("questions.q2a1") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carSize"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Mittelklasse"
                required
              >
                {{ $t("questions.q2a2") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.carSize"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="SUV"
                required
              >
                {{ $t("questions.q2a3") }}
              </b-form-radio>
            </div>
          </b-form-group>
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q3HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q3") }}</h5>
        <div class="d-flex align-items-center">
          <b-form-input
            id="kilometer"
            v-model="questions.kilometer"
            class="number-input"
            type="number"
            step="1000"
            required
          ></b-form-input>
          <p class="p-2 mb-0">{{ $t("questions.q3a1") }}</p>
        </div>

        </div>
        <hr class="mt-4">
        <h2>{{ $t("questions.subtitle2") }}</h2>
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q4HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q4") }}</h5>
        <p>{{ $t("questions.q4help") }}</p>
          <b-form-group id="input-group-3">
            <b-form-input
              id="kurzstrecke"
              v-model="questions.shortFlights"
              type="range"
              min="0"
              max="6"
              step="0.5"
              value="1"
              class="slider"
              required
            ></b-form-input>
                <div class="d-flex justify-content-between">
                  <p class="slider-number">0</p>
                  <p class="slider-number">1</p>
                  <p class="slider-number">2</p>
                  <p class="slider-number">3</p>
                  <p class="slider-number">4</p>
                  <p class="slider-number">5</p>
                  <p class="slider-number">6</p>
                </div>
          </b-form-group>
          
          <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q41HH") }}</h5>
          <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q41") }}</h5>
          <p>{{ $t("questions.q41help") }}</p>
            <b-form-group id="input-group-3">
              <b-form-input
                id="mittelstrecke"
                v-model="questions.midFlights"
                type="range"
                min="0"
                max="6"
                step="0.5"
                value="1"
                class="slider"
                required
              ></b-form-input>
                  <div class="d-flex justify-content-between">
                    <p class="slider-number">0</p>
                    <p class="slider-number">1</p>
                    <p class="slider-number">2</p>
                    <p class="slider-number">3</p>
                    <p class="slider-number">4</p>
                    <p class="slider-number">5</p>
                    <p class="slider-number">6</p>
                  </div>
            </b-form-group>

          <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q5HH") }}</h5>
          <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q5") }}</h5>
          <p>{{ $t("questions.q5help") }}</p>
          <b-form-group id="input-group-3">
            <b-form-input
              id="langstrecke"
              v-model="questions.longFlights"
              type="range"
              min="0"
              max="6"
              step="0.5"
              value="1"
              class="slider"
              required
            ></b-form-input>
                <div class="d-flex justify-content-between">
                  <p class="slider-number">0</p>
                  <p class="slider-number">1</p>
                  <p class="slider-number">2</p>
                  <p class="slider-number">3</p>
                  <p class="slider-number">4</p>
                  <p class="slider-number">5</p>
                  <p class="slider-number">6</p>
                </div>
          </b-form-group>
        <div v-if="questions.shortFlights + questions.longFlights + questions.midFlights != 0">           
          <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q51HH") }}</h5>
          <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q51") }}</h5>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="questions.business"
                id="business"
                class="number-input"
                type="number"
                placeholder="0"
                required
              ></b-form-input>
              <p class="p-2 mb-0">{{ $t("questions.q51a1") }}</p>
            </div>
        </div>   
        <hr class="mt-4">
        <h2>{{ $t("questions.subtitle3") }}</h2>
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q6HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q6") }}</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="multiple-choice">
              <b-form-radio 
                v-model="questions.heatingType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Ölheizung"
              >
                {{ $t("questions.q6a1") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.heatingType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Gasheizung"
              >
                {{ $t("questions.q6a2") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.heatingType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="Andere"
              >
                {{ $t("questions.q6a4") }}
              </b-form-radio>
            </div>
          </b-form-group>
          <div v-if="questions.heatingType == 'Ölheizung' || questions.heatingType == 'Gasheizung' ">
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q7HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q7") }}</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="multiple-choice">
              <b-form-radio 
                v-model="questions.houseType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="93"
              >
                {{ $t("questions.q7a1") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.houseType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="75"
              >
                {{ $t("questions.q7a2") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.houseType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="66"
              >
                {{ $t("questions.q7a3") }}
              </b-form-radio>
              <b-form-radio 
                v-model="questions.houseType"
                :aria-describedby="ariaDescribedby"
                class="radio-answer"
                value="63"
              >
                {{ $t("questions.q7a4") }}
              </b-form-radio>
            </div>
          </b-form-group>
        <h5 v-if="questions.calculationType == 'Haushalt'">{{ $t("questions.q8HH") }}</h5>
        <h5 v-if="questions.calculationType == 'Mich'">{{ $t("questions.q8") }}</h5>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="questions.livingArea"
              id="wohnfläche"
              class="number-input"
              type="number"
              placeholder="80"
              required
            ></b-form-input>
            <p class="p-2 mb-0">{{ $t("questions.q8a1") }}</p>
          </div>
          </div>
        <hr class="mt-4">
        <b-button :to="{name: 'Result', query: {
          calculationType: questions.calculationType,
          kilometer: questions.kilometer,
          carType: questions.carType,
          carSize: questions.carSize,
          shortFlights: questions.shortFlights,
          midFlights: questions.midFlights,
          longFlights: questions.longFlights,
          business: questions.business,
          heatingType: questions.heatingType,
          houseType: questions.houseType,
          livingArea: questions.livingArea,
          numberPeople: questions.numberPeople,
        }}" class="button-secondary" type="submit">{{ $t("questions.button" )}}</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Questions",
  components: {},
  data() {
    return {
      questions: {
        calculationType: this.$route.query.calculationType || "Mich",
        kilometer: this.$route.query.kilometer || 10000,
        carType: this.$route.query.carType || "Kein Auto",
        carSize: this.$route.query.carSize || "Kleinklasse",
        shortFlights: this.$route.query.shortFlights || 0,
        midFlights: this.$route.query.midFlights || 0,
        longFlights: this.$route.query.longFlights || 0,
        business: this.$route.query.business || 0,
        heatingType: this.$route.query.heatingType || "Andere",
        houseType: this.$route.query.houseType || "93",
        livingArea: this.$route.query.livingArea || "80",
        numberPeople: this.$route.query.numberPeople || "1",
      }
    }
  }
};
</script>

<style scoped>

.question-box {
  text-align: left;
  box-shadow: 0 0 0 0.5px rgb(0 20 40 / 10%), 0 2px 8px 0 rgb(50 55 90 / 20%);
  border-radius: 3px;
  padding: 10px 20px;
  margin-bottom: 40px;
}

.text-box {
  text-align: left;
  width: 100%;
}

.radio-answer {
  margin-right: 20px;
}

.number-input {
  width: 10%;
  min-width: 100px;
}

#input-group-3 {
  margin-left: -3.5%;
}

.slider {
  width: 93%;
  margin-left: 3.5%;
}

.slider-container {
  width: 100%
}

.slider-number {
  width:9.09%;
  text-align: center;
}

@media (min-width: 768px) {
  .multiple-choice {
    display: flex;
  }
}

</style>