function tipps(i18n){return [
    {
        title: i18n.t("tipps.title1"),
        desc: i18n.t("tipps.text1"),
        descHH: i18n.t("tipps.text1HH"),
        imgUrl: require("../src/assets/house.svg"),
        /* how much can i save with this tipp? */
            //super complex function
        rate(answers, results) {
            if (results.y2030.heating <= 0 && (answers.houseType == 93 || answers.houseType == 66)) {
                return 39 * answers.numberPeople - results.y2030.heating
                } else {
                return 0;
            }
        },
    },
    {
        title: i18n.t("tipps.title2"),
        desc: i18n.t("tipps.text2"),
        descHH: i18n.t("tipps.text2HH"),
        imgUrl: require("../src/assets/tram.svg"),
        /* how much can i save with this tipp? */
        rate(answers) {
            //super complex function
            if (answers.shortFlights >= 1) {
              return answers.numberPeople * 30;
            } else {
              return 0;
            }
        },
    },
    {
        title: i18n.t("tipps.title4"),
        desc: i18n.t("tipps.text4"),
        descHH: i18n.t("tipps.text4HH"),
        imgUrl: require("../src/assets/mountain.svg"),
        /* how much can i save with this tipp? */
        rate(answers) {
            if (answers.longFlights >= 1) {
                return answers.numberPeople * 120
              } else {
                return 0;
              }
        },
    },
    {
        title: i18n.t("tipps.title5"),
        desc: i18n.t("tipps.text5"),
        descHH: i18n.t("tipps.text5HH"),
        imgUrl: require("../src/assets/car.jpg"),
        /* how much can i save with this tipp? */
        rate(answers, results) {
            if (results.y2030.car <= 0) {
                return -results.y2030.car
              } else {
                return 0;
              }
        },
    },
    {
        title: i18n.t("tipps.title6"),
        desc: i18n.t("tipps.text6"),
        descHH: i18n.t("tipps.text6HH"),
        imgUrl: require("../src/assets/car.jpg"),
        /* how much can i save with this tipp? */
        rate(answers, results) {
            if (answers.carSize == "SUV") {
                return - Math.round(results.y2022.car * 0.35)
              } else {
                return 0;
              }
        },
    },
    {
        title: i18n.t("tipps.title7"),
        desc: i18n.t("tipps.text7"),
        descHH: i18n.t("tipps.text7HH"),
        imgUrl: require("../src/assets/velo.svg"),
        /* how much can i save with this tipp? */
        rate(answers, results) {
            if (results.y2030.car <= 0 && answers.kilometer >= 5000 ) {
              if (answers.carType == "Benzin" && answers.carSize =="Kleinklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 6.83 * 0.07);
              } else if (answers.carType == "Benzin" && answers.carSize =="Mittelklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 8.42 * 0.07);
              } else if (answers.carType == "Benzin" && answers.carSize =="SUV") {
                return Math.round(answers.kilometer * 0.2 / 100 * 10.01 * 0.07);
              } else if (answers.carType == "Diesel" && answers.carSize =="Kleinklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 5.23 * 0.07);
              } else if (answers.carType == "Diesel" && answers.carSize =="Mittelklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 6.70 * 0.07);
              } else if (answers.carType == "Diesel" && answers.carSize =="SUV") {
                return Math.round(answers.kilometer * 0.2 / 100 * 8.39 * 0.07);
              } else if (answers.carType == "Erdgas" && answers.carSize =="Kleinklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 4.94 * 0.07);
              } else if (answers.carType == "Erdgas" && answers.carSize =="Mittelklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 6.08 * 0.07);
              } else if (answers.carType == "Erdgas" && answers.carSize =="SUV") {
                return Math.round(answers.kilometer * 0.2 / 100 * 7.22 * 0.07);
              } else if (answers.carType == "Hybrid" && answers.carSize =="Kleinklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 4.22 * 0.07);
              } else if (answers.carType == "Hybrid" && answers.carSize =="Mittelklasse") {
                return Math.round(answers.kilometer * 0.2 / 100 * 5.2 * 0.07);
              } else if (answers.carType == "Hybrid" && answers.carSize =="SUV") {
                return Math.round(answers.kilometer * 0.2 / 100 * 6.18 * 0.07);
              }
            }
            else {
              return 0;
            }
        },
    }
]}

export {tipps};